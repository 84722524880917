// for width < 1440px
@media (max-width : 1440px) {
    .what-we-do{
        flex-direction: column;

        .wwa-img{
            max-width: 50%;
            border-radius: 15px;
        }
    
        .wwa-right-content{
    
            .wwa-text{
                font-size: large;
                text-align: left;
                line-height: 1.5;
            }


        }
    }
}


// for width < 1040px
@media (max-width : 1040px) {
    .what-we-do{
        margin-block: 20px 60px;

        .wwa-img{
            display: none;
        }
    
        .wwa-rigth-content{
            margin-left: 0px !important;
    
            .wwa-text{
                font-size: large;
                text-align: left;
                line-height: 1.5;
            }
        }

    }
}


.who-we-are{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80vw;

    .wwa-img{
        border-radius: 15px;
        // animation: forwards 1s imgAnimate;
    }

    @keyframes imgAnimate {
        0%{
            transform: translateX(-50vw);
        }
        100%{
            transform: translateX(0);
        }
    }

    .wwa-right-content{
        display: flex;
        flex-direction: column;
        gap: 20px;
        align-items: flex-start;
        margin-left: 30px;
        text-align: left;
        // animation: forwards 1s contentAnimate;

        .wwa-title{
            font-size: xx-large;
            font-weight: bold;
            color: #0A2134;
        }

        .wwa-sub-title{
            font-size: x-large;
            font-weight: bold;
            color: #43505B;
        }

        .wwa-text{
            font-size: medium;
            text-align: left;
        }

        .wwa-btn{
            font-size: 20px;
            color: white;
            background-color: #C12A45;
            border-radius: 10px;
            cursor: pointer;
            transition: all 0.3s;
            padding: 10px 15px;

            &:hover{
                border: #C12A45 solid 1px;
                background-color: white;
                color: #C12A45;
            }
        }
    }

    @keyframes contentAnimate {
        0%{
            transform: translateX(100vw);
        }
        100%{
            transform: translateX(0);
        }
    }
}

