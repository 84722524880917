.about-page {
  display: flex;
  flex-direction: column;
  align-items: center;

  .topbar {
    position: relative;
    color: white !important;
    font-weight: bold !important;
    font-size: xxx-large !important;
    height: 60vh;

    .topbar-img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100vw;
      height: 60vh;
      object-fit: cover;
      z-index: auto;
    }

    .topbar-text {
      position: relative;
      z-index: 1;
      background: rgba(0, 0, 0, 0.6);
      height: 60vh;
      width: 100vw;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  .numbers {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    margin: 40px 0;
    height: 75px;

    .section {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .number {
        color: #961128;
        font-size: 48px;
        font-weight: bold;
      }

      .text {
        font-size: 20px;
        color: #43505b;
      }
    }

    .line {
      width: 0.5px;
      height: 100%;
      background-color: #d4d4d4;
    }
  }

  .mission-box {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 80vw;
    margin: 40px 0;

    .mission-img {
      border-radius: 15px;
      width: 50%;
    }

    .info {
      display: flex;
      flex-direction: column;
      gap: 20px;
      align-items: flex-start;
      justify-content: flex-start;
      text-align: left;

      &.mission{
        margin-right: 30px;
      }

      &.vision{
        margin-left: 30px;
      }

      .title {
        font-size: xx-large;
        font-weight: bold;
        color: #0a2134;
      }

      .text {
        font-size: medium;
        text-align: left;
      }
    }
  }
}
