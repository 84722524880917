.testimony-container {
    // background-color: #f7f7f7;
    margin: 20px 0;
    width: 80vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 100px;
  
    .title {
      max-width: 1300px;
      margin-block: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 10px;
  
      .testimony-title {
        font-size: 36px;
        font-weight: 700;
        margin-bottom: 1rem !important;
      }
  
      .testimony-sub-title {
        font-size: 16px;
        font-weight: 600;
        color: #43505b;
        margin-bottom: 1rem;
        color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
      }
    }
  
    .slider {
      --size-3: 5%;
      max-width: 1300px;
      max-height: 500px;
      padding: 50px 2px;
      display: grid;
      gap: var(--size-3);
      grid-auto-flow: column;
      grid-template-columns: 30% 30% 30% 30% 30%;
      // align-content: center;
      // justify-content: center;
  
      overflow-x: auto;
      // overscroll-behavior-inline: contain;
      scroll-behavior: smooth;
  
      .testimonial-card {
        background: #fff;
        --size-2: 40px;
        width: calc(100% - --size-2 * 2);
        height: calc(100% - --size-2 * 2);
        display: grid;
        grid-template-rows: 75px 30px auto auto;
        padding: var(--size-2);
        padding-top: 0;
        border-radius: 1.25rem;
        box-shadow: #eee 0 0 5px 1px;
  
        .testimonial-img {
          background: #fff;
          margin-inline: auto;
          width: 100px;
          height: 100px;
          border: 2px solid #eee;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          border: 5px solid #efefef;
          box-shadow: #eee 0 0 5px 1px;
  
          position: relative;
          top: -50px;
  
          .client-img {
            width: 50px;
            vertical-align: middle;
          }
        }
  
        .testimonial-name {
          font-size: 19px;
          font-weight: 700;
  
          display: flex;
          align-items: center;
          justify-content: center;
        }
  
        .rating {
          .star {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 5%;
  
            .rating-star {
              max-height: 15px;
            }
          }
  
          span {
            font-size: 40px;
            color: #961128;
          }
        }
  
        .testimonial-description {
          font-size: 15px;
          font-weight: 500;
          line-height: 23px;
          color: #000;
          margin-bottom: 1rem;
        }
      }
    }
  
    .dots {
      width: 1300px;
      display: flex;
      align-items: center;
      justify-content: center;
  
  
      .owl-dots {
        width: max-content;
        display: flex !important;
        align-items: center;
        justify-content: center;
  
        // position: relative;
        // top: -3.5rem !important;
  
        .owl-dot {
          height: 20px;
          width: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 0 5px;
          border-radius: 50%;
          border: 1px solid #961128;
  
          &:hover {
            cursor: pointer;
          }
  
          span {
            height: 6px;
            width: 6px;
            border-radius: 50%;
            display: block;
            background-color: #961128;
          }
        }
  
  
        .active{
          border-color: #2699fb;
  
  
          span{
            background-color: #2699fb;
          }
        }
      }
    }
  }
  