.footer{
    width: 100vw;
    border-top: #d4d4d4 solid 1px;

    .top-part{
        display: flex;
        justify-content: space-evenly;
        align-items: flex-start;
        padding: 30px;

        .info{
            text-align: left;
            width: 20%;

            .logo{
                width: 100px;
                height: 40px;
                object-fit: cover;
                padding-bottom: 48px;
            }

            .stay-text{
                font-size: 24px;
                font-weight: 600;
            }

            .subscribe-text{
                padding: 30px 0;
            }

            .email-box{
                height: 40px;
                border: black solid 1px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                border-radius: 20px;

                .email-input{
                    border: none;
                    border-radius: 20px;
                    padding: 10px;

                    &:focus{
                        outline: none;
                    }
                }

                .send-btn-wrapper{
                    background-color: #961128;
                    width: fit-content;
                    border-radius: 100%;
                    cursor: pointer;

                    .send-btn{
                        padding: 8px;
                        height: 24px;
                        filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(46deg) brightness(101%) contrast(101%);
                    }
                }
            }
        }

        .company, .services, .call-us{
            text-align: left;
            width: 20%;
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;

            .title{
                font-size: 24px;
                font-weight: 600;
                padding-bottom: 20px;
            }

            .list{
                display: flex;
                flex-direction: column;
                flex-wrap: wrap;
                gap: 10px;
                font-weight: 500;

                div{
                    cursor: pointer;
                    color: #43505b;
                }
            }

            .address, .mobile, .email{
                padding: 10px 0;

                .first-row{
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    padding: 5px 0;

                    .icon{
                        height: 20px;
                        padding-right: 5px;
                    }
                }

                .mobile-text, .address-text, .email-text{
                    cursor: pointer;
                }
            }
        }
    }

    .bottom-part{
        padding: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: calc(100vw - 40px);
        background-color: #F8F9FD;
        font-size: 18px;
    }

    // for width < 640px
    @media (max-width : 640px) {
        .top-part{
            display: flex;
            justify-content: space-evenly;
            align-items: flex-start;
            padding: 30px;
    
            .info{
                text-align: center;
                width: 80%;
    
                .logo{
                    width: 100px;
                    height: 40px;
                    object-fit: cover;
                    padding-bottom: 48px;
                }
    
                .stay-text{
                    font-size: 24px;
                    font-weight: 600;
                }
    
                .subscribe-text{
                    padding: 30px 0;
                }
    
                .email-box{
                    width: 100%;
                    height: 40px;
                    border: black solid 1px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    border-radius: 20px;
    
                    .email-input{
                        width: 80%;
                        border: none;
                        border-radius: 20px;
                        padding: 10px;
    
                        &:focus{
                            outline: none;
                        }
                    }
    
                    .send-btn-wrapper{
                        width: 20%;
                        background-color: #961128;
                        width: fit-content;
                        border-radius: 100%;
                        cursor: pointer;
    
                        .send-btn{
                            padding: 8px;
                            height: 24px;
                            filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(46deg) brightness(101%) contrast(101%);
                        }
                    }
                }
            }
    
            .company, .services, .call-us{
                display: none;
            }
        }
    
        .bottom-part{
            padding: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: calc(100vw - 40px);
            background-color: #F8F9FD;
            font-size: 18px;
        }
    }
}