.navbar {
  height: 90px;
  padding: 0px 40px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  z-index: 10;
  // margin-top: 10px;

  .left {
    height: 100%;
    padding: 10px 0;

    .logo {
      padding: 0;
      cursor: pointer;
      height: 90px;
      object-fit: cover;
    }
  }

  .mid {
    padding: 20px 0;

    .nav-elements {
      display: flex;
      gap: 30px;

      .close-btn {
        display: none;
      }

      .nav-element {
        cursor: pointer;
        color: #43505b;
        font-weight: bold;

        &.active,
        &:hover {
          color: #961128;
        }
      }

      .speakToExpertBtn{
        display: none;

        a{
          color: #961128;
        }
      }

      .nav-elem-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        .arrow {
          height: 20px;
          margin: 0 4px;
          margin-top: 1px;
        }

        &:hover {
          .arrow {
            filter: invert(13%) sepia(33%) saturate(7457%) hue-rotate(337deg)
              brightness(97%) contrast(100%);
          }

          .nav-element {
            color: #961128;
          }
        }
      }

      .dropdown-wrapper {
        position: relative;
        // height: 41px;

        .dropdown-menu {
          display: none;
          max-width: 300px;
          position: absolute;
          text-wrap: nowrap;
          top: 20px;
          left: -50%;
          z-index: 10;
          background: #fff !important;
          color: #43505b !important;
          // scale: 0;
          // transition: all 0.1s ease-in-out;


          &.show {
            display: block;
            position: absolute;
            text-wrap: nowrap;
            top: 20px;
            left: -50%;
            z-index: 5;
            // scale: 1;
            // transition: all 0.1s ease-in-out;

            .title:hover {
              cursor: pointer;
              color: #961128;
            }
          }
          
          .header {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            padding: 24px 40px;
            padding-top: 40px !important;
            gap: 24px;
            font-weight: bold;
          }
        }
      }
    }
  }

  .right {
    padding: 20px 0;

    .speak-to-expert-btn {
      background-color: #961128;
      color: white;
      cursor: pointer;
      font-weight: bold;
      padding: 10px;
    }
  }

  .menu {
    display: none;
  }
}

// for width < 1040px
@media (max-width: 1040px) {
  .navbar {
    justify-content: space-between;

    .mid {
      // display: none;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 10;
      transform: translateX(0%);
      transition: 0.5s;

      background: #00000025;
      width: 100vw;
      height: 100vh;
      padding: 0;

      .nav-elements {
        background: #fff;
        width: 300px;
        height: 100vh;
        padding: 40px;

        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 10px;

        position: relative;
        top: 0;

        .close-btn {
          display: block;
          width: 100%;
          display: flex;
          justify-content: flex-end;
          align-items: center;
        }

        .nav-element {
          cursor: pointer;
          color: #43505b;
          font-weight: bold;

          &.active,
          &:hover {
            color: #961128;
          }
        }

        .speakToExpertBtn{
          display: block;
        }

        .nav-elem-wrapper {
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;

          .arrow {
            height: 20px;
            margin: 0 4px;
            margin-top: 1px;
          }

          &:hover {
            .arrow {
              filter: invert(13%) sepia(33%) saturate(7457%) hue-rotate(337deg)
                brightness(97%) contrast(100%);
            }

            .nav-element {
              color: #961128;
            }
          }
        }


        .dropdown-wrapper {
          position: relative;
  
          .dropdown-menu {
            display: none;
            max-width: 300px;
            text-wrap: nowrap;
            z-index: 5;
            background: #fff !important;
            color: #43505b !important;
            // scale: 0;
            // transition: all 0.1s ease-in-out;
  
            &.show {
              display: block;
              text-wrap: nowrap;
              z-index: 5;
              // scale: 1;
              // transition: all 0.1s ease-in-out;
  
              .title:hover {
                cursor: pointer;
                color: #961128;
              }
            }
  
            .header {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: flex-start;
              margin-top: 14px;
              padding: 0;
              padding-top: 0px !important;
              margin-left: 56px;
              gap: 10px;
              font-weight: bold;
              font-size: 14px;
            }
          }
        }

        .move-down{
          margin-bottom: 116px;
        }
      }
    }

    .hideMenu {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 10;
      transform: translateX(-100%);
      transition: 0.5s;
    }

    .right {
      display: none;
    }

    .menu {
      display: block;
      padding: 20px;

      .menu-btn {
        width: 44px;
        cursor: pointer;
      }
    }
  }
}

// for width < 640px
@media (max-width: 640px) {
  .navbar {
    justify-content: space-between;
    padding: 0px;

    .mid {
      // display: none;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 10;
      transform: translateX(0%);
      transition: 0.5s;

      background: #00000025;
      width: 100vw;
      height: 100vh;
      padding: 0;

      .nav-elements {
        background: #fff;
        width: 300px;
        height: 100vh;
        padding: 40px;

        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 10px;

        position: relative;
        top: 0;

        .close-btn {
          display: block;
          width: 100%;
          display: flex;
          justify-content: flex-end;
          align-items: center;
        }

        .nav-element {
          cursor: pointer;
          color: #43505b;
          font-weight: bold;

          &.active,
          &:hover {
            color: #961128;
          }
        }

        .nav-elem-wrapper {
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;

          .arrow {
            height: 20px;
            margin: 0 4px;
            margin-top: 1px;
          }

          &:hover {
            .arrow {
              filter: invert(13%) sepia(33%) saturate(7457%) hue-rotate(337deg)
                brightness(97%) contrast(100%);
            }

            .nav-element {
              color: #961128;
            }
          }
        }


        .dropdown-wrapper {
          position: relative;
          // height: 41px;
  
          .dropdown-menu {
            display: none;
            max-width: 300px;
            position: absolute;
            text-wrap: nowrap;
            top: 30px;
            left: -50%;
            z-index: 5;
            background: #fff !important;
            color: #43505b !important;
            // scale: 0;
            // transition: all 0.1s ease-in-out;
  
            &.show {
              display: block;
              text-wrap: nowrap;
              z-index: 5;
              // scale: 1;
              // transition: all 0.1s ease-in-out;
  
              .title:hover {
                cursor: pointer;
                color: #961128;
              }
            }
  
            .header {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: flex-start;
              margin-top: 14px;
              padding: 0;
              padding-top: 0px !important;
              margin-left: 56px;
              gap: 10px;
              font-weight: bold;
              font-size: 14px;
            }
          }
        }
      }
    }

    .hideMenu {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 10;
      transform: translateX(-150%);
      transition: 0.5s;
    }

    .right {
      display: none;
    }

    .menu {
      display: block;
      padding: 20px;

      .menu-btn {
        width: 44px;
        cursor: pointer;
      }
    }
  }
}
